<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 215.9 47.66"
    class="fill-current"
  >
    <path
      d="M13.21,38.94a15.26,15.26,0,0,1-1.41,4.12,8.72,8.72,0,0,1-2.16,2.68,7.86,7.86,0,0,1-2.86,1.47,12.65,12.65,0,0,1-3.46.45,11.17,11.17,0,0,1-2-.17A10.41,10.41,0,0,1,0,47.17L.61,44a6.86,6.86,0,0,0,1.26.3,8.85,8.85,0,0,0,1.28.11A7.71,7.71,0,0,0,5.59,44a4.34,4.34,0,0,0,1.82-1.19,7,7,0,0,0,1.35-2.2,19.59,19.59,0,0,0,1-3.37L14.2,15.93H10l.68-3.23h4.25l.82-4a11.32,11.32,0,0,1,3.44-6.55A9.07,9.07,0,0,1,25.24,0a12.6,12.6,0,0,1,2.13.17,8.8,8.8,0,0,1,1.49.36l-.58,3.14a10.47,10.47,0,0,0-1.46-.29,9.92,9.92,0,0,0-1.43-.1,5.39,5.39,0,0,0-3.9,1.4A8,8,0,0,0,19.42,9l-.75,3.69H25l-.68,3.23H18l-4.78,23Z"
    />
    <path
      d="M45.64,21.17a18,18,0,0,1-.31,3.23,18.34,18.34,0,0,1-1,3.41,17.4,17.4,0,0,1-1.66,3.24,12.07,12.07,0,0,1-2.4,2.73,11.37,11.37,0,0,1-3.18,1.88,10.87,10.87,0,0,1-4,.71,9.63,9.63,0,0,1-3.38-.57,6.85,6.85,0,0,1-2.6-1.69,7.78,7.78,0,0,1-1.66-2.73,10.84,10.84,0,0,1-.59-3.7,19.41,19.41,0,0,1,.3-3.28,18.82,18.82,0,0,1,1-3.48,18.47,18.47,0,0,1,1.66-3.33,12.71,12.71,0,0,1,2.4-2.81,11,11,0,0,1,3.18-2,10.63,10.63,0,0,1,4-.73,9.57,9.57,0,0,1,3.39.57,7.07,7.07,0,0,1,2.61,1.7,7.65,7.65,0,0,1,1.69,2.83,12.22,12.22,0,0,1,.59,4Zm-8.49-5.88a6.08,6.08,0,0,0-2.58.54,7.46,7.46,0,0,0-2.11,1.44A9.8,9.8,0,0,0,30.8,19.4,15.49,15.49,0,0,0,29.59,22a16.81,16.81,0,0,0-.72,2.79,15.84,15.84,0,0,0-.25,2.77,9.11,9.11,0,0,0,.28,2.3,4.89,4.89,0,0,0,.86,1.79,3.9,3.9,0,0,0,1.48,1.14,5.14,5.14,0,0,0,2.12.4,6.24,6.24,0,0,0,2.56-.53A7.61,7.61,0,0,0,38,31.18a10,10,0,0,0,1.67-2.11,15.39,15.39,0,0,0,1.2-2.56,17.6,17.6,0,0,0,.73-2.79,16.87,16.87,0,0,0,.24-2.79,8.73,8.73,0,0,0-.28-2.32,5,5,0,0,0-.86-1.77,3.9,3.9,0,0,0-1.48-1.14,5.14,5.14,0,0,0-2.12-.4Z"
    />
    <path
      d="M70.06,21.17a18,18,0,0,1-.31,3.23,18.3,18.3,0,0,1-1,3.41,17.44,17.44,0,0,1-1.67,3.24,12.29,12.29,0,0,1-2.4,2.73,11.23,11.23,0,0,1-3.18,1.88,10.87,10.87,0,0,1-4,.71,9.57,9.57,0,0,1-3.37-.57,6.78,6.78,0,0,1-2.6-1.69,7.66,7.66,0,0,1-1.67-2.73,10.84,10.84,0,0,1-.59-3.7,19.41,19.41,0,0,1,.3-3.28,18.82,18.82,0,0,1,1-3.48,17.88,17.88,0,0,1,1.67-3.33,12.67,12.67,0,0,1,2.39-2.81,11,11,0,0,1,3.18-2,10.63,10.63,0,0,1,4-.73,9.57,9.57,0,0,1,3.39.57,7.07,7.07,0,0,1,2.61,1.7,7.65,7.65,0,0,1,1.69,2.83,12,12,0,0,1,.59,4Zm-8.5-5.88a6.07,6.07,0,0,0-2.57.54,7.46,7.46,0,0,0-2.11,1.44,9.8,9.8,0,0,0-1.66,2.13A15.49,15.49,0,0,0,54,22a16.81,16.81,0,0,0-.72,2.79A15.84,15.84,0,0,0,53,27.53a9.11,9.11,0,0,0,.28,2.3,4.89,4.89,0,0,0,.86,1.79,3.9,3.9,0,0,0,1.48,1.14,5.14,5.14,0,0,0,2.12.4,6.24,6.24,0,0,0,2.56-.53,7.61,7.61,0,0,0,2.12-1.45,9.68,9.68,0,0,0,1.66-2.11,14.67,14.67,0,0,0,1.21-2.56,17.6,17.6,0,0,0,.73-2.79,16.87,16.87,0,0,0,.24-2.79A8.73,8.73,0,0,0,66,18.61a5,5,0,0,0-.86-1.77,4,4,0,0,0-1.48-1.14,5.14,5.14,0,0,0-2.12-.4Z"
    />
    <path
      d="M91.59,33.34A2.34,2.34,0,0,1,91.32,32c0-.16,0-.5.05-.75s.07-.5.12-.76c0,0,4.17-20.64,5.63-27.79.3-1.49.82-1.61,1.6-1.89.08,0,0-.15,0-.13l-4.58.67a.44.44,0,0,0-.37.34L90.93,15.11h-.1A5.61,5.61,0,0,0,88.65,13a6.9,6.9,0,0,0-3.39-.78,8.66,8.66,0,0,0-3.33.65,9.87,9.87,0,0,0-2.9,1.8,12.66,12.66,0,0,0-2.28,2.72,18.51,18.51,0,0,0-1.67,3.4,21.55,21.55,0,0,0-1,3.85,23,23,0,0,0-.35,4.05,10.85,10.85,0,0,0,.43,3.17,7.23,7.23,0,0,0,1.26,2.44,5.6,5.6,0,0,0,2,1.57,6.54,6.54,0,0,0,2.72.54,8.14,8.14,0,0,0,3.79-1A10.52,10.52,0,0,0,87.63,32h.1a4.38,4.38,0,0,0,.84,3.19,3.87,3.87,0,0,0,2.91.94q.63,0,1.26-.06a10.43,10.43,0,0,0,1.53-.34c.54-.15,1.45-.43,2.25-.68a.23.23,0,0,0-.07-.45c-1.75,0-4.27-.14-4.87-1.26ZM89.09,24a15.38,15.38,0,0,1-1.29,3.88,12.42,12.42,0,0,1-2,2.88,8.14,8.14,0,0,1-2.32,1.79,5.44,5.44,0,0,1-2.42.62A4.1,4.1,0,0,1,79.76,33a2.83,2.83,0,0,1-1.16-.8,4.25,4.25,0,0,1-.83-1.56,8.48,8.48,0,0,1-.31-2.52,17.39,17.39,0,0,1,.21-2.57,17.91,17.91,0,0,1,.64-2.8A18.21,18.21,0,0,1,79.41,20,11.15,11.15,0,0,1,81,17.65,7.43,7.43,0,0,1,83,16a5.46,5.46,0,0,1,2.56-.61,5.33,5.33,0,0,1,1.64.23,5.69,5.69,0,0,1,1.32.59,5.63,5.63,0,0,1,1,.82,8.44,8.44,0,0,1,.77.9L89.1,24Z"
    />
    <path
      d="M140.5,36.13l4.9-23.52h5.73l-4.9,23.52Zm5.87-28.91L147.64,1h6l-1.26,6.24Z"
    />
    <path
      d="M164.65,29a1.6,1.6,0,0,0-.46-1.19A4.15,4.15,0,0,0,163,27a12,12,0,0,0-1.66-.65c-.63-.19-1.25-.41-1.88-.65a13.78,13.78,0,0,1-1.69-.8,6.07,6.07,0,0,1-1.46-1.16,5.17,5.17,0,0,1-1-1.64,6.19,6.19,0,0,1-.37-2.28,7.51,7.51,0,0,1,.75-3.46,7,7,0,0,1,2-2.43,8.66,8.66,0,0,1,2.94-1.43,12.73,12.73,0,0,1,3.52-.47,16.44,16.44,0,0,1,2.1.13c.7.09,1.39.21,2.06.36s1.32.34,1.93.54a12.51,12.51,0,0,1,1.68.67L171,18.22a22.49,22.49,0,0,0-3.58-1.15,16,16,0,0,0-3.31-.39,4.17,4.17,0,0,0-2.58.69,2.1,2.1,0,0,0-.92,1.77,1.82,1.82,0,0,0,.33,1.1,2.94,2.94,0,0,0,.88.79,6.72,6.72,0,0,0,1.26.61l1.49.55c.66.25,1.34.51,2,.8a7.16,7.16,0,0,1,1.84,1.16,5.66,5.66,0,0,1,1.35,1.76,5.79,5.79,0,0,1,.52,2.62,7.86,7.86,0,0,1-.76,3.55,7.26,7.26,0,0,1-2.18,2.57A10,10,0,0,1,164,36.21a16.5,16.5,0,0,1-4.32.52,20.42,20.42,0,0,1-2.53-.14,21.2,21.2,0,0,1-2.15-.37,16.87,16.87,0,0,1-1.67-.46,10,10,0,0,1-1.13-.44l.95-4.58a22.31,22.31,0,0,0,3.24,1,16,16,0,0,0,3.31.35,6.87,6.87,0,0,0,3.69-.81A2.57,2.57,0,0,0,164.64,29Z"
    />
    <path
      d="M190.82,17.34h-6.11l-2.33,11.34c-.08.36-.14.67-.19.94a4.6,4.6,0,0,0-.06.69,1.45,1.45,0,0,0,.49,1.25,3.05,3.05,0,0,0,1.7.35,15.85,15.85,0,0,0,2.11-.18c.86-.12,1.63-.27,2.31-.43l-1,4.78a19.41,19.41,0,0,1-2.54.49,21.59,21.59,0,0,1-2.58.17,10.63,10.63,0,0,1-2.89-.35,5.45,5.45,0,0,1-2-1,3.94,3.94,0,0,1-1.17-1.6,5.75,5.75,0,0,1-.38-2.14c0-.27,0-.6.05-1a11.55,11.55,0,0,1,.22-1.37L179,17.34h-4.4l1-4.73H180L181.33,6h5.73l-1.36,6.58h6.12l-1,4.73Z"
    />
    <path
      d="M212.31,30.48a1.15,1.15,0,0,0,.35.94,1.57,1.57,0,0,0,1,.27,12,12,0,0,0,1.73-.14l-1,4.61a14.12,14.12,0,0,1-2.89.32,3.87,3.87,0,0,1-4.32-3.6h-.09a10.32,10.32,0,0,1-3.61,3,8.92,8.92,0,0,1-3.77.9,6.72,6.72,0,0,1-2.85-.58,6,6,0,0,1-2.13-1.65,7.39,7.39,0,0,1-1.34-2.57,11.64,11.64,0,0,1-.46-3.35,24.23,24.23,0,0,1,.33-4,21.61,21.61,0,0,1,1-3.82,18.71,18.71,0,0,1,1.62-3.44,12.29,12.29,0,0,1,2.22-2.79,10.07,10.07,0,0,1,2.81-1.87,8.4,8.4,0,0,1,3.38-.68,7.41,7.41,0,0,1,3.18.7,7,7,0,0,1,2.62,2.21h.1l.85-2.33h4.9L212.5,29c-.07.31-.11.6-.15.86a5.82,5.82,0,0,0,0,.62ZM209,18.56a4.61,4.61,0,0,0-.57-.64,3.61,3.61,0,0,0-.83-.58,4.63,4.63,0,0,0-1-.43,4.4,4.4,0,0,0-1.23-.17,4,4,0,0,0-2.07.54,6.4,6.4,0,0,0-1.68,1.46,9.62,9.62,0,0,0-1.27,2.09,16.89,16.89,0,0,0-.89,2.44,19,19,0,0,0-.52,2.49,17,17,0,0,0-.17,2.27,5.6,5.6,0,0,0,.64,3,2.12,2.12,0,0,0,1.89,1,4.37,4.37,0,0,0,2.47-.84,8.08,8.08,0,0,0,2.21-2.29A10.9,10.9,0,0,0,207,26.69a17.63,17.63,0,0,0,.81-2.84L209,18.56Z"
    />
    <path
      d="M133.74,32.5a9.76,9.76,0,0,1-.09-1.91,10.91,10.91,0,0,1,.2-1.71L139.07,3A3.65,3.65,0,0,1,140.75.59l.4-.25A.17.17,0,0,0,141,.05l-6.62,1a.74.74,0,0,0-.6.56L128,29.31A12.93,12.93,0,0,0,127.7,32a4.29,4.29,0,0,0,1.3,3.39,5.68,5.68,0,0,0,3.87,1.15,13.7,13.7,0,0,0,2.08-.18,15.21,15.21,0,0,0,2.5-.65c.09,0,.07-.46,0-.47-.95-.06-3.24-.19-3.68-2.7Z"
    />
    <path
      d="M108.12,28.05l-5.71-.17c-.76,0-1.12-.37-1-.94a1.21,1.21,0,0,1,1.38-.87c3.82.1,7.67.2,11.56.32.91,0,1.31.78.7,1.4a1.72,1.72,0,0,1-1.09.41c-2,0-3.9-.09-5.84-.15Z"
    />
    <path
      d="M112.43,23.49l-8.92-.22a1.24,1.24,0,0,1-.77-.17.94.94,0,0,1-.26-.79,1.75,1.75,0,0,1,.7-.76,1.55,1.55,0,0,1,.78-.09l17.83.39h.31c.67.05,1,.43.9,1a1.2,1.2,0,0,1-1.3.88l-9.28-.22Z"
    />
    <path
      d="M119.74,28.49l-1.82,0a.75.75,0,0,1-.61-.82.81.81,0,0,1,.62-.86l3.67,0c.29,0,.57.35.57,1a.78.78,0,0,1-.58.74c-.62,0-1.24,0-1.86,0Z"
    />
    <path
      d="M110.77,32.94l-8.91-.22a1.24,1.24,0,0,1-.77-.17.91.91,0,0,1-.26-.79,1.69,1.69,0,0,1,.7-.76,1.42,1.42,0,0,1,.77-.09l17.84.39h.31c.67,0,1,.43.9,1a1.21,1.21,0,0,1-1.3.88l-9.28-.22Z"
    />
    <path
      d="M104.57,14.87c1.15-5.25,3.9-8.52,8.34-9.92,0-.37,0-.76,0-1.15,0-1.12.65-1.81,1.59-1.83.38,0,.75,0,1.12,0,.88,0,1.32.71,1.15,1.76-.06.41-.15.82-.24,1.23,4.18,1.46,6.26,4.82,6.2,10.18a4.17,4.17,0,0,1,.5,0,.89.89,0,0,1,.87,1A11.72,11.72,0,0,1,123.9,18a1,1,0,0,1-.93.87h-.32q-9.51-.18-18.76-.36a1.18,1.18,0,0,1-.69-.19c-.62-.45-.36-2.82.36-3.29a1.6,1.6,0,0,1,.6-.18,2.06,2.06,0,0,1,.43,0Z"
    />
  </svg>
</template>
